import {Facet, Sorting} from "@elastic/react-search-ui";
import React from "react";
import {useTranslation} from "react-i18next";


function Filter({wasSearched}) {
  const { t } = useTranslation();

  return (
      <div>
      {wasSearched && (
        <Sorting
          label={t("sortBy")}
          sortOptions={[
            {
              name: t("byRelevance"),
              value: "",
              direction: "",
            },
            {
              name: t("byDate"),
              value: "published",
              direction: "desc",
            },
            {
              name: t("byScore"),
              value: "cvss",
              direction: "desc",
            },
            {
              name: t("byTitle"),
              value: "title",
              direction: "asc",
            }
          ]}
        />
      )}

      <Facet
        field="type"
        label={t("bulletinType")}
        filterType="any"
        isFilterable={true}
      />
      <Facet
        field="bulletinFamily"
        label={t("bulletinFamily")}
        filterType="any"
        isFilterable={true}
      />
      <Facet
        field="vulnerabilityType"
        label={t("vulnerabilityType")}
        filterType="any"
        isFilterable={true}
      />
      <Facet field="score" label={t("cvssScore")} filterType="any"/>
    </div>
  )
}

export default Filter

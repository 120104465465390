import React from "react";

import "./style.scss";
import {useTranslation} from "react-i18next";

function Benefits() {
  const { t } = useTranslation();

  return (
    <div className="v13s-main-page-benefits" >
      <div>
        <h3>{t("main.benefit.1")}</h3>
        <p>
          {t("main.benefit.1.text")}
        </p>
      </div>
      <div>
        <h3>{t("main.benefit.2")}</h3>
        <p>
          {t("main.benefit.2.text")}
        </p>
      </div>
      <div>
        <h3>{t("main.benefit.3")}</h3>
        <p>
          {t("main.benefit.3.text")}
        </p>
      </div>
      <div>
        <h3>{t("main.benefit.4")}</h3>
        <p>
          {t("main.benefit.4.text")}
        </p>
      </div>
    </div>
  );
}

export default Benefits;

import getScoreRangeName from "../../lib/getScoreRangeName";
import Markdown from "../Markdown";

import "./style.scss";
import "../../../node_modules/highlight.js/styles/idea.css"

import Button from "../Button";
import {useTranslation} from "react-i18next";
import Highlight from 'react-highlight'

function getClassNameByScore(score) {
  const range = getScoreRangeName(score);
  return `v13s-bulletin-info__score v13s-bulletin-info__score--${range}`;
}

function BulletinInfo({ info }) {
  const { t } = useTranslation();
  const { title, description, type, bulletinFamily, score, href, sourceData } = info;
  const published = new Date(info.published).toUTCString();
  const classNameByScore = getClassNameByScore(score);

  return (
    <div className="v13s-bulletin-info">
      <h1 className="v13s-bulletin-info__title">{title}</h1>
      <div className="v13s-bulletin-info__labels">
        <div className="v13s-bulletin-info__label">{t("published")}</div>
        <div>{published}</div>
        <div className="v13s-bulletin-info__label">{t("type")}</div>
        <div>{type}</div>
        <div className="v13s-bulletin-info__label">{t("family")}</div>
        <div>{bulletinFamily}</div>
        <div className="v13s-bulletin-info__label">{t("cvssScore")}</div>
        <div className={classNameByScore}>{score}</div>
      </div>
      <div className="v13s-bulletin-info__description-label">{t("description")}</div>
      <p className="v13s-bulletin-info__description">
        <Markdown>{description}</Markdown>
      </p>
      {sourceData && <div className="v13s-bulletin-info__exploit">
        <Highlight className='bash'>{sourceData}</Highlight>
      </div>}

    </div>
  );
}

export default BulletinInfo;

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from "./json/lang/ru.json"

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru: {
        translation: ru
      }
    }
  })
export default i18n

import React from "react";
import {Paging, PagingInfo, Results, ResultsPerPage,} from "@elastic/react-search-ui";
import {Layout} from "@elastic/react-search-ui-views";

import SearchResult from "../../ui/SearchResult";
import SearchHeader from "../../ui/SearchHeader";
import Filter from "../../ui/Filter"
import Footer from "../../ui/Footer";


function SearchPage({wasSearched}) {

  return (
    <React.Fragment>
      <Layout
        header={<SearchHeader/>}
        sideContent={
          <Filter wasSearched={wasSearched}/>
        }
        bodyContent={
          <Results
            titleField="title"
            urlField="href"
            shouldTrackClickThrough={true}
            resultView={SearchResult}
          />
        }
        bodyHeader={
          <React.Fragment>
            {wasSearched && <PagingInfo/>}
            {wasSearched && <ResultsPerPage/>}
          </React.Fragment>
        }
        bodyFooter={<Paging/>}
      />
      <Footer/>
    </React.Fragment>
  );
}

export default SearchPage;

import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import React from "react";

import NotFoundPage from "../NotFoundPage";
import {runBulletinRequest} from "../../lib/runRequest";
import BulletinInfo from "../../ui/BulletinInfo";
import {Layout} from "@elastic/react-search-ui-views";
import SearchHeader from "../../ui/SearchHeader";
import Filter from "../../ui/Filter";

import './style.scss'
import Footer from "../../ui/Footer";
import {useTranslation} from "react-i18next";

function BulletinPage({wasSearched}) {
  let { bulletinId } = useParams();
  let navigate = useNavigate()
  let { t } = useTranslation();

  const { isLoading, error, data } = useQuery(["bulletin", bulletinId], () =>
    runBulletinRequest(bulletinId)
  );

  if (error) return <NotFoundPage />;

  return (
    <>
      <Layout
        header={<SearchHeader/>}
        sideContent={
          <Filter wasSearched={wasSearched}/>
        }
        bodyContent={
          isLoading ? t('loading') : <BulletinInfo info={data} />
        }
        bodyHeader={
            <div className="v13s-bulletin-page__back" onClick={() => navigate(-1)}>
              &lt; {t('back')}
            </div>
        }
      />
      <Footer/>
    </>
  );
}

export default BulletinPage;

import React from "react";

import "./style.scss";
import SearchField from "../../../ui/SearchField";
import {useTranslation} from "react-i18next";

function Search() {
  const { t } = useTranslation();

  const handleSubmit = (query) => {
    window.location = '/search?q=' + query
  }

  return (
    <div className="v13s-main-page-search" >

      <h1>{t("main.search.h1")}</h1>
      <h2>{t("main.search.h2")}</h2>
      <SearchField className="v13s-main-page-search__search" onSubmit={handleSubmit}/>
    </div>
  );
}

export default Search;

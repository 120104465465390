import CentralLayout from "../../ui/Layout/CentralLayout";
import Logo from "../../ui/Logo";

import "./style.scss";

function NotFoundPage() {
  return (
    <CentralLayout>
      <div className="v13s-not-found-page__label">404</div>
      <Logo />
    </CentralLayout>
  );
}

export default NotFoundPage;

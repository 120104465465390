import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";

import './style.scss'
import Footer from "../../ui/Footer";
import {useTranslation} from "react-i18next";
import Header from "../../ui/Header";
import Button from "../../ui/Button";


function Subscriptions({wasSearched}) {
  let { bulletinId } = useParams();
  let navigate = useNavigate()
  let { t } = useTranslation();

  let [sent, setSent] = useState(false)

  const handleSend = ({name, email, question}) => {
    console.log('[{name, email, question}]', {name, email, question})
    setSent(true)
  }

  return (
    <div className="v13s-api-page">

      <Header/>

      <div className="v13s-api-page__body" >
        <h2>Подписка на новые уязвимости и эксплойты</h2>
        <br/>
        <p>
          D-search позволяет получать уведомления о новых уязвимостях и эксплойтах для интересующих вас систем и программном обеспечении.
          Подпишите свою команду безопасности и администраторов системы на мгновенное получение подробной информации.
        </p>
        <br/>

        <Button onClick={() => navigate('/contacts')}>Подписаться</Button>
      </div>

      <Footer/>

    </div>
  );
}

export default Subscriptions;

import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";

import './style.scss'
import Footer from "../../ui/Footer";
import {useTranslation} from "react-i18next";
import Header from "../../ui/Header";
import Button from "../../ui/Button";


function TosPage({wasSearched}) {
  let {bulletinId} = useParams();
  let navigate = useNavigate()
  let {t} = useTranslation();

  let [sent, setSent] = useState(false)

  const handleSend = (data) => {
    console.log('[{name, email, question}]', data)
    fetch('/api/contact', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    setSent(true)
  }

  return (
    <div className="v13s-tos-page">

      <Header/>

      <div className="v13s-tos-page__body">
        <h2>Правила использования</h2>

        <p>
          Программа для ЭВМ D-search лицензируется и тарифицируется на основе количества API Credits в месяц, доступного Лицензиату.
          <br/>
          <br/>
          Лицензиар заявляет и гарантирует, что:<br/>
          <ul>
            <li>Является разработчиком Программного обеспечения;</li>
            <li>Программное обеспечение предназначено для поиска уязвимостей в стороннем программном обеспечении,  который базируется на открытых источниках в сети Интернет;</li>
            <li>Лицензиар является законным владельцем Программного обеспечения (за исключением любых включенных в них данных Лицензиата) и имеет все необходимые права на Программное обеспечение для предоставления Лицензиату лицензии по настоящему Соглашению;</li>
            <li>Программное обеспечение не нарушает требований законодательства Российской Федерации, а также авторских прав третьих лиц.</li>
          </ul>
        </p>

      </div>

      <Footer/>

    </div>
  );
}

export default TosPage;

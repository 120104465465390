import React from "react";
import {Route, Routes} from "react-router-dom";

import "./styles/styles.scss";
import "./styles/search-ui-v13s-theme.scss";

import NotFoundPage from "./pages/NotFoundPage";
import SearchPage from "./pages/SearchPage";
import BulletinPage from "./pages/BulletinPage";
import {ErrorBoundary, SearchProvider, WithSearch} from "@elastic/react-search-ui";
import searchProviderConfig from "./lib/searchProviderConfig";
import MainPage from "./pages/MainPage";
import ContactUsPage from "./pages/ContactUsPage";
import './i18n'
import APIPage from "./pages/APIPage";
import Subscriptions from "./pages/Subscriptions";
import TosPage from "./pages/TosPage";

function App() {

  return (
    <SearchProvider config={searchProviderConfig}>
      <WithSearch mapContextToProps={({wasSearched}) => ({wasSearched})}>
        {({wasSearched}) => (
            <ErrorBoundary>

              <Routes>
                <Route exact path="/" element={<MainPage/>} />
                <Route path="/search" element={<SearchPage wasSearched={wasSearched}/>} />
                <Route path="bulletin/:bulletinId" element={<BulletinPage wasSearched={wasSearched}/>} />
                <Route path="/contacts" element={<ContactUsPage/>} />
                <Route path="/tos" element={<TosPage/>} />
                <Route path="/about-api" element={<APIPage/>} />
                <Route path="/subscriptions" element={<Subscriptions/>} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>

            </ErrorBoundary>
        )}
      </WithSearch>
    </SearchProvider>

  );
}

export default App;

import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";

import './style.scss'
import Footer from "../../ui/Footer";
import {useTranslation} from "react-i18next";
import Header from "../../ui/Header";
import Button from "../../ui/Button";


function APIPage({wasSearched}) {
  let { bulletinId } = useParams();
  let navigate = useNavigate()
  let { t } = useTranslation();

  let [sent, setSent] = useState(false)

  const handleSend = ({name, email, question}) => {
    console.log('[{name, email, question}]', {name, email, question})
    setSent(true)
  }

  return (
    <div className="v13s-api-page">

      <Header/>

      <div className="v13s-api-page__body" >
        <h2>API</h2>
        <br/>
        <p>
          D-search.ru предоставляет API для интеграции с вашими инструментами безопасности и системами мониторинга.
          API позволяет получать свежие данные о уязвимостях, эксплойтах, новостях и статьях через гибкую систему запросов.
        </p>
        <br/>
        <p>Для доступа к API свяжитесь с нами</p>

        <Button onClick={() => navigate('/contacts')}>Получить API ключ</Button>
      </div>

      <Footer/>

    </div>
  );
}

export default APIPage;

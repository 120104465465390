import "./style.scss";


function Button({ children, onClick, href, target }) {

  if (href) {
    return <a className="v13s-button" href={href} target={target}>
      {children}
    </a>
  }

  return (
    <div className="v13s-button" onClick={onClick}>
      {children}
    </div>
  );
}

export default Button;

import logoLong from "./logo-long.svg";
import {Link} from "react-router-dom";

function Logo({ className = "" }) {
  return (
    <Link to='/'>
      <img
        className={className}
        alt="vulnerabilities"
        src={logoLong}
        height={38}
      />
    </Link>
  );
}

export default Logo;

import { Link } from "react-router-dom";

import getScoreRangeName from "../../lib/getScoreRangeName";

import "./style.scss";

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type];
}

function getRaw(result, field) {
  return getFieldType(result, field, "raw");
}

function getSnippet(result, field) {
  return getFieldType(result, field, "snippet");
}

function htmlEscape(str) {
  if (!str) return "";

  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function getEscapedField(result, field) {
  // Fallback to raw values here, because non-string fields
  // will not have a snippet fallback. Raw values MUST be html escaped.
  const safeField =
    getSnippet(result, field) || htmlEscape(getRaw(result, field));
  return Array.isArray(safeField) ? safeField.join(", ") : safeField;
}

function getClassNameByScore(score) {
  const range = getScoreRangeName(score);
  return `v13s-search-result__score v13s-search-result__score--${range}`;
}

function SearchResult({ result }) {
  const id = getRaw(result, "id");
  const title = getEscapedField(result, "title");
  const description = getRaw(result, "description");
  const type = getRaw(result, "type");
  const modified = new Date(getRaw(result, "modified")).toUTCString();
  const score = getRaw(result, "score") || 0;
  const classNameByScore = getClassNameByScore(score);

  return (
    <li className="sui-result">
      <Link to={`/bulletin/${id}`} className="v13s-search-result">
        <div className={classNameByScore}>{score}</div>
        <div className="v13s-search-result__title">
          <span
            className="sui-result__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <div className="v13s-search-result__description">
          <span>{description.slice(0,250) + '...'}</span>
        </div>
        <div className="v13s-search-result__details">{`${modified} – ${type}`}</div>
        <div className="v13s-search-result__chevron"></div>
      </Link>
    </li>
  );
}

export default SearchResult;

import React from "react";
import {useNavigate} from 'react-router-dom';

import "./style.scss";
import Header from "../../ui/Header";
import Footer from "../../ui/Footer";
import Search from "./Search";
import Benefits from "./Benefits";

function MainPage({wasSearched}) {

  const navigate = useNavigate();

  const handleSubmit = (query) => {
    window.location = '/search?q=' + query
  }

  return (
    <div className="v13s-main-page">

      <Header/>

      <div className="v13s-main-page__body" >

        <Search/>
        <Benefits/>
      </div>

      <Footer/>

    </div>
  );
}

export default MainPage;

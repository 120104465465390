import React, {useEffect} from "react";
import { SearchBox } from "@elastic/react-search-ui";
import cn from 'classnames';

import './style.scss'
import {useTranslation} from "react-i18next";

const ID = "search-field"


function SearchField({className, onSubmit}) {
  const { t } = useTranslation();

  const focusSearch = () => {
    const inputBox = document.getElementById(ID);
    const input = inputBox.querySelector('input')
    if (input !== document.activeElement) {
      input.focus();
      return true;
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '/') {
        focusSearch() && event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className={cn(className, 'v13s-search-field')} id={ID}>
      <SearchBox
        onSubmit={onSubmit}
        inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
          <>
            <div className="sui-search-box__wrapper">
              <input
                {...getInputProps({
                  placeholder: t("search.input")
                })}
              />
              {getAutocomplete()}
            </div>
            <input
              {...getButtonProps({
                value: t("search.button")
              })}
            />
          </>
        )}
        autocompleteMinimumCharacters={3}
        autocompleteResults={{
          sectionTitle: t("results"),
          titleField: "title",
        }}
        autocompleteSuggestions={true}
        onSelectAutocomplete={(selection, { completeSuggestion }) => {
          if (!selection) return;

          if (!selection.suggestion) {
            const bulletinId = selection.id ? selection.id.raw : "";
            if (bulletinId) {
              const url = `/bulletin/${bulletinId}`;
              window.open(url, "_blank");
            }
          } else {
            completeSuggestion(selection.suggestion);
          }
        }}
      />
    </div>
  );
}

export default SearchField;

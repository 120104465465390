import React from "react";
import Logo from "../../ui/Logo";
import {Link, useNavigate} from 'react-router-dom';

import "./style.scss";
import Button from "../Button";
import {useTranslation} from "react-i18next";

function Header({wasSearched}) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="v13s-header-wrapper">
      <div className="v13s-header">
        <Logo className="v13s-header__logo"/>
        <div className="v13s-header__content">
          <ul className="v13s-header__menu">
            <li><a
              href="/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=bulletinFamily&filters%5B0%5D%5Bvalues%5D%5B0%5D=NVD&filters%5B0%5D%5Btype%5D=any&sort-field=published&sort-direction=desc">{t("advisories")}</a>
            </li>
            <li><a
              href="/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=bulletinFamily&filters%5B0%5D%5Bvalues%5D%5B0%5D=exploit&filters%5B0%5D%5Btype%5D=any&sort-field=published&sort-direction=desc">{t("exploits")}</a>
            </li>
            <li><a href="/subscriptions">{t("subscriptions")}</a></li>
            <li><a href="/about-api">API</a></li>
          </ul>
          <Button onClick={() => navigate('/contacts')}>{t('contactUs')}</Button>
        </div>
      </div>
    </div>
  );
}

export default Header;

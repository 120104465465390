let API_HOST = process.env.NODE_ENV === 'production' ? "" : "http://127.0.0.1:8080";

export async function runSearchRequest(body) {
  const response = await fetch(`${API_HOST}/api/search`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
  return response.json();
}

export async function runBulletinRequest(bulletinId) {
  const response = await fetch(`${API_HOST}/api/bulletin/${bulletinId}`, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });
  if (!response.ok) {
    throw new Error("Not found");
  }

  const json = await response.json();
  return json._source;
}

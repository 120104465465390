import React from "react";
import Logo from "../Logo";
import {Link} from "react-router-dom";


import './style.scss'
import {useTranslation} from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return <footer className="v13s-footer">

    <div className="v13s-footer__wrapper">

      <div className="v13s-footer__company">

        <div className="v13s-footer__copyright">
          Программа для ЭВМ "D-Search"<br/>
        </div>
      </div>

      <div className="v13s-footer__columns">
        <div className="v13s-footer__column">
          <div>{t("search")}</div>
          <ul>
            <li><a target="_blank" href="/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=bulletinFamily&filters%5B0%5D%5Bvalues%5D%5B0%5D=NVD&filters%5B0%5D%5Btype%5D=any&sort-field=published&sort-direction=desc">{t("advisories")}</a></li>
            <li><a target="_blank" href="/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=bulletinFamily&filters%5B0%5D%5Bvalues%5D%5B0%5D=exploit&filters%5B0%5D%5Btype%5D=any&sort-field=published&sort-direction=desc">{t("exploits")}</a></li>
            <li><a target="_blank" href="/subscriptions">{t("subscriptions")}</a></li>
            <li><a target="_blank" href="/about-api">{t("api")}</a></li>
          </ul>
        </div>

        <div className="v13s-footer__column">
          <div>{t("company")}</div>
          <ul>
            <li><Link to="/contacts">{t("contactUs")}</Link></li>
            <li><Link to="/tos">{t("tos")}</Link></li>
          </ul>
        </div>
      </div>

    </div>

  </footer>
}



export default Footer

import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";

import './style.scss'
import Footer from "../../ui/Footer";
import {useTranslation} from "react-i18next";
import Header from "../../ui/Header";
import Button from "../../ui/Button";

const Success = () => {
  return <div className="v13s-contact-page__success">
    Спасибо за обращение, мы обязательно вернемся к вам в самое ближайшее время
    <Button href='/search?q=cve&size=n_20_n&sort-field=published&sort-direction=desc'>Вернуться к поиску</Button>
  </div>
}

const Form = ({onSend}) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [question, setQuestion] = useState();

  return <div className="v13s-contact-page__form">
    <label htmlFor='firstName'>Ваше имя</label> <br/>
    <input name='firstName' id='firstName' onChange={e => setName(e.target.value)}/> <br/>

    <label htmlFor='email'>E-mail</label> <br/>
    <input name='email' id='email' onChange={e => setEmail(e.target.value)}/><br/>

    <label htmlFor='question'>Ваш вопрос</label> <br/>
    <textarea name='question' id='question' onChange={e => setQuestion(e.target.value)}/><br/>

    <div>
      <button className="v13s-contact-page__button" onClick={() => onSend({name, email, question})}>Отправить</button>
    </div>
  </div>
}

function BulletinPage({wasSearched}) {
  let {bulletinId} = useParams();
  let navigate = useNavigate()
  let {t} = useTranslation();

  let [sent, setSent] = useState(false)

  const handleSend = (data) => {
    console.log('[{name, email, question}]', data)
    fetch('/api/contact', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    setSent(true)
  }

  return (
    <div className="v13s-contact-page">

      <Header/>

      <div className="v13s-contact-page__body">
        <h2>Связаться с нами</h2>

        <ul>
          <li>По вопросам приобретения: <a href="mailto:sales@dcservices.ru">sales@dcservices.ru</a></li>
          <li>По вопросам технической поддержки клиентов: <a href="mailto:support@dcservices.ru">support@dcservices.ru</a></li>
          <li>По вопросам сотрудничества для компаний, оказывающих услуги в сфере информационной безопасности: <a href="mailto:sales@dcservices.ru">sales@dcservices.ru</a></li>
        </ul>
      </div>

      <Footer/>

    </div>
  );
}

export default BulletinPage;

import React, {useEffect} from "react";
import { SearchBox } from "@elastic/react-search-ui";

import Logo from "../Logo";

import "./style.scss";
import SearchField from "../SearchField";

function SearchHeader() {

  return (
    <div className="v13s-search-header">
      <Logo className="v13s-search-header__logo" />
      <SearchField className="v13s-search-header__search" />
    </div>
  );
}

export default SearchHeader;

import buildRequest from "./buildRequest";
import {runSearchRequest} from "./runRequest";
import buildState from "./buildState";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";


const IS_DEV_MODE = false //process.env.NODE_ENV !== "production";

const searchProviderConfig = {
  debug: IS_DEV_MODE,
  hasA11yNotifications: true,
  onResultClick: () => {
    /* Not implemented */
  },
  onAutocompleteResultClick: () => {
    /* Not implemented */
  },
  onAutocomplete: async ({searchTerm}) => {
    const requestBody = buildRequest({searchTerm});
    const json = await runSearchRequest(requestBody);
    const state = buildState(json);
    console.log('[AC] ', state)
    return {
      autocompletedResults: state.results,
    };
  },
  onSearch: async (state) => {
    const {resultsPerPage} = state;
    const requestBody = buildRequest(state);
    // Note that this could be optimized by running all of these requests
    // at the same time. Kept simple here for clarity.
    const responseJson = await runSearchRequest(requestBody);
    const responseJsonWithDisjunctiveFacetCounts =
      await applyDisjunctiveFaceting(responseJson, state, ["score"]);
    return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
  },
};

export default searchProviderConfig
